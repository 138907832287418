(function () {
  'use strict';

  angular
  .module('neo.authentication.login')
  .config(config);

  function config($stateProvider) {
    $stateProvider
    .state('authentication.login', {
      templateUrl: 'authentication/login/login.tpl.html',
      controller: 'LoginCtrl',
      controllerAs: 'vm'
    });
  }
}());
